import PropTypes from "prop-types";
import React from "react";
import numericaideas from "../images/numericaideas.png";

const popularContent = [{
    title: 'Lift And Shift Cloud Migration to AWS ElasticBeanstalk',
    img: 'https://github.com/numerica-ideas/community/raw/master/aws/springboot-migration-elasticbeanstalk/images/cicd-github-actions.png',
    description: 'This video series Migrates a "Spring Boot + MySQL" App to AWS Cloud using ElasticBeanstalk service with no Cloud experience required.',
    link: 'https://www.youtube.com/playlist?list=PLJl2liPyo6s3oQkBT2UWbuSfrwO4aS-9Z',
    isVideo: true
  },
  {
    title: 'Deploying a Spring Boot App with Docker in a Kubernetes Cluster',
    img: 'https://assets.numericaideas.com/2023/03/Dockerized-Spring-Boot-Deployment-in-K8s-Cluster.png',
    description: 'In this demo, we will deploy a Spring Boot application using Docker and Kubernetes with MySQL as the database management system.',
    link: 'https://blog.numericaideas.com/deploying-springboot-app-with-docker-and-kubernetes'
  },
  {
    title: 'React Hooks to Manage Component State and Lifecycle',
    img: 'https://assets.numericaideas.com/2023/09/react-hooks.png',
    description: 'A Hook is a special function that allows developers to use state and other React features without having to write a class.',
    link: 'https://blog.numericaideas.com/react-hooks'
  },
  {
    title: 'Lambda Performance Improvement with SnapStart',
    img: 'https://assets.numericaideas.com/2022/12/NI-Lambda-SnapStart.png',
    description: 'SnapStart is a performance optimization option that helps to reduce the initialization time of Lambda functions at no additional costs.',
    link: 'https://blog.numericaideas.com/lambda-performance-improvement-with-snapstart'
  },
  {
    title: 'Deploy WordPress on a 2-Tier AWS Architecture with Terraform',
    img: 'https://assets.numericaideas.com/2023/06/Deploying-WordPress-on-a-2-Tier-AWS-Architecture-with-Terraform-1.png',
    description: 'Deploying WordPress on a 2-tier AWS architecture with Terraform offers a robust and scalable solution to host your website.',
    link: 'https://blog.numericaideas.com/deploy-wordpress-2-tier-aws-architecture-with-terraform'
  },
  {
    title: 'Introduction to Terraform',
    img: 'https://assets.numericaideas.com/2023/09/TerraformWorkflow-1.png',
    description: 'Terraform is an Infrastructure as Code (IaC) tool that allows you to automatically create and manage resources for anything that has an accessible API.',
    link: 'https://youtu.be/tJ6L1332WU4',
    isVideo: true
  },
  {
    title: 'Apply SOLID Principles in a Real Angular Project',
    img: 'https://assets.numericaideas.com/2023/09/solid-principles-in-angular.png',
    description: 'Apply SOLID principles to a feature-rich task management application built using Angular.',
    link: 'https://blog.numericaideas.com/solid-principles-in-angular-cheat-sheet'
  },
  {
    title: 'Spring Boot Actuator: Monitoring',
    img: 'https://assets.numericaideas.com/2023/09/spring-boot-actuator.png',
    description: 'Spring Boot Actuator (V2.x) is a powerful toolset that provides extensive monitoring, management, and operational capabilities to Spring Boot applications.',
    link: 'https://blog.numericaideas.com/spring-boot-actuator'
  }]

const Header = (props) => (
  <header id="header" style={props.timeout ? { display: "none" } : {}}>
    <div className="logo">
      <img src={numericaideas} alt="Numerica Ideas" />
    </div>
    <div className="content">
      <nav>
        <ul style={{ justifyContent: "space-around" }}>
          <li>
            <button>
              <a
                className="blog-link"
                rel="noreferrer noopener"
                href="https://github.com/numerica-ideas/community#ni-community-activities"
                target="_blank"
              >
                Activities
              </a>
            </button>
          </li>
          {/* <li>
            <button
              onClick={() => {
                props.onOpenArticle("award");
              }}
            >
              Award
            </button>
          </li> */}
          <li>
            <button>
              <a
                className="blog-link"
                rel="noreferrer noopener"
                href="https://www.youtube.com/@numericaideas/channels?sub_confirmation=1"
                target="_blank"
              >
                Videos
              </a>
            </button>
          </li>
          <li>
            <button>
              <a
                className="blog-link"
                rel="noreferrer noopener"
                href="https://blog.numericaideas.com/"
                target="_blank"
              >
                Blog
              </a>
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                props.onOpenArticle("contact");
              }}
            >
              Contact
            </button>
          </li>
        </ul>
      </nav>

      <div className="inner">
        {/* <h1>NUMERICA IDEAS</h1> */}
        <h2>
          Tech topics to sharpen your cloud and software architecture skills
        </h2>
        <p className="motto">"Discover, Learn, Build, &amp; Share" 🚀</p>
        <p>No hour of life is wasted that is spent gaining invaluable skills</p>
        <p>Join our <a href="https://discord.numericaideas.com"><b>Discord Community</b></a> with +150 members</p>
        <iframe width="267" height="200" src="https://www.youtube.com/embed/6bdCyAZCUTg?si=ZN_3K-EfSC3UjOjX&amp;controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>

        <h1 className="popular">POPULAR CONTENT</h1>
        <div className="row">

          {popularContent.map((content, index) => (
            <div className="card" key={index}>
              { content.isVideo && <div className="card-badge">VIDEO</div> }
              <a href={content.link} target="_blank"><img className="card-img" src={content.img} /></a>
              <div className="card-body">
                <h3><a href={content.link} target="_blank">{content.title}</a></h3>
                <p>
                  {content.description}
                </p>
              </div>
            </div>
          ))}

        </div>
        <p>See more content on <a href="https://blog.numericaideas.com" title="NumericaIdeas Tech Blog" target="_blank"><b>the blog</b></a> and on <a href="https://www.youtube.com/@numericaideas/channels?sub_confirmation=1" title="NumericaIdeas YouTube Channel" target="_blank"><b>YouTube</b></a>.</p>
      </div>
    </div>
    
  </header>
);

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
};

export default Header;
