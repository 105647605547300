import React from "react";
import Particles from "react-tsparticles";
import Layout from "../components/layout";

import Header from "../components/Header";
import Main from "../components/Main";
import Footer from "../components/Footer";
import ParticleComponent from "../components/particles";
import { navigate } from "gatsby";


class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isArticleVisible: false,
      timeout: false,
      articleTimeout: false,
      article: "",
      loading: "is-loading",
    };
    this.handleOpenArticle = this.handleOpenArticle.bind(this);
    this.handleCloseArticle = this.handleCloseArticle.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ loading: "" });
    }, 100);
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleOpenArticle(article) {
    // this.setState({
    //   isArticleVisible: !this.state.isArticleVisible,
    //   article,
    // });

    // setTimeout(() => {
    //   this.setState({
    //     timeout: !this.state.timeout,
    //   });
    // }, 325);

    // setTimeout(() => {
    //   this.setState({
    //     articleTimeout: !this.state.articleTimeout,
    //   });
    // }, 350);

    switch (article) {
      case "about":
        navigate("about");
        break;
      case "award":
        navigate("award");
        break;
      case "contact":
        navigate("contact");
        break;
      default:
        navigate("/");
        break;
    }
  }

  handleCloseArticle() {
    this.setState({
      articleTimeout: !this.state.articleTimeout,
    });

    setTimeout(() => {
      this.setState({
        timeout: !this.state.timeout,
      });
    }, 325);

    setTimeout(() => {
      this.setState({
        isArticleVisible: !this.state.isArticleVisible,
        article: "",
      });
    }, 350);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (this.state.isArticleVisible) {
        this.handleCloseArticle();
      }
    }
  }

  particlesInit(main) {}

  particlesLoaded(container) {}

  render() {
    return (
      <Layout location={this.props.location}>
        <div
          className={`body ${this.state.loading} ${
            this.state.isArticleVisible ? "is-article-visible" : ""
          }`}
        >
          <ParticleComponent>
            <Header
              onOpenArticle={this.handleOpenArticle}
              timeout={this.state.timeout}
            />
            <Main
              isArticleVisible={this.state.isArticleVisible}
              timeout={this.state.timeout}
              articleTimeout={this.state.articleTimeout}
              article={this.state.article}
              onCloseArticle={this.handleCloseArticle}
              setWrapperRef={this.setWrapperRef}
            />
            <Footer timeout={this.state.timeout} />
          </ParticleComponent>
        </div>
      </Layout>
    );
  }
}

export default IndexPage;
