import PropTypes from "prop-types";
import React from "react";

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle();
        }}
      ></div>
    );

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: "flex" } : { display: "none" }}
      >
        <article
          id="blog"
          className={`${this.props.article === "blog" ? "active" : ""} ${
            this.props.articleTimeout ? "timeout" : ""
          }`}
          style={{ display: "none" }}
        >
          <h2 className="major">Blog</h2>
          <p>
            Click{" "}
            <a
              rel="noreferrer noopener"
              href="https://blog.numericaideas.com/"
              target="_blank"
            >
              here
            </a>{" "}
            to access our <strong>free</strong> knowledge center.
          </p>
          {close}
        </article>
        <article
          id="about"
          className={`${this.props.article === "about" ? "active" : ""} ${
            this.props.articleTimeout ? "timeout" : ""
          }`}
          style={{ display: "none" }}
        >
          <h2 className="major">About</h2>
          <p>
            Go to the{" "}
            <a rel="noreferrer noopener" href="/about">
              about page.
            </a>
          </p>
          {close}
        </article>
        <article
          id="award"
          className={`${this.props.article === "award" ? "active" : ""} ${
            this.props.articleTimeout ? "timeout" : ""
          }`}
          style={{ display: "none" }}
        >
          <h2 className="major">award</h2>
          <p>
            Go to the{" "}
            <a rel="noreferrer noopener" href="/award">
              award page.
            </a>
          </p>
          {close}
        </article>
        <article
          id="contact"
          className={`${this.props.article === "contact" ? "active" : ""} ${
            this.props.articleTimeout ? "timeout" : ""
          }`}
          style={{ display: "none" }}
        >
          <h2 className="major">Contact</h2>
          <p>
            Go to the{" "}
            <a rel="noreferrer noopener" href="/contact">
              contact page.
            </a>
          </p>
          {close}
        </article>
      </div>
    );
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
};

export default Main;
